.app{
  min-height: 200vh;
  .startView{
    background-image: url("./../assets/mainBck.jpg");
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
    display: flex;
  }
  .photoAuthor{
    position: absolute;
    left: 25px;
    bottom: 15px;
    padding: 5px;
    font-size: 22px;
    color: #959595;
    text-decoration: underline;

    transition: 0.2s ease-in color,
                0.2s ease-in border-color;
    a,a:hover,a:visited{
      color: inherit;
    }

    &:hover{
      color: #111;
    }
  }
}

@media only screen and (max-width: 987.97px) {
  .app{
    .startView{
      flex-direction: column;
      justify-content: center;
      .myName{
        height: 300px;
        width: 100%;
        min-height: initial;
      }
      .mainTiles{
        padding-top: 60px;
        padding-bottom: 60px;
        width: 100%;
        min-height: initial;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .app{
    .startView{
      justify-content: flex-start;
      padding-top: 50px;
      .mainTiles{
        padding-top: 20px;
        padding-bottom: 0px;
      }
      .myName{
        height: 250px;
        .nameContainer{
          padding-right: 50px;
          padding-left: 20px;
          width: 85%;
          font-size: 70px;
        }
      }
      .tile{
        &::after{
          transform: none;
        }
      }
      .mainTiles{
        .blank{
          display: none;
        }
        .tilesContainer{
          grid-template-columns: repeat(2,50vw);
          grid-template-rows: repeat(2, 105px);
          // justify-items: center;
        }
        .tile{
          width: 100px;
          height: 100px;
          font-size: 20px;
        }
        .myWorkTile{
          transform: translate(0, 0);
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 2;
          margin-left: auto;
        }
        .blogTile{
          transform: translate(0, 0);
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        .facebookTile{
          transform: translate(0, 0);
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
          margin-left: auto;
        }
        .contactTileLink{
          transform: translate(0, 0);
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
        }
      }
    }
  }
}