@font-face {
  font-family: "Nunito";
  src: url("./../assets/fonts/Nunito-Regular.ttf");
}
@font-face {
  font-family: "Oswald";
  src: url("./../assets/fonts/Oswald-Regular.ttf");
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Oswald';
}
*,*::before,*::after{
  box-sizing: border-box;
}

.fadeInButton{
  position: relative;
  width: 100px;
  font-size: 22px;
  padding: 4px 5px 7px 5px;
  text-align: center;
  cursor: pointer;
  color: white;
  .text{
    position: relative;
    z-index: 10;
  }
  &::after, &::before{
    content: "";
    position: absolute;
    left: 0px; top: 0px;
    width: 100%;
    height: 100%;
    
    transition: transform 0.2s ease-in;
  }
  &::after{
    border: 2px solid #003c70;
    transform: translate(4px, -4px);
  }
  &::before{
    background-color: #44a5ff;
    transform: translate(-4px, 4px);
  }

  &:hover{
    &::after, &::before{
      transform: translateX(0px);
    }
  }
}

.hoverableLogo{
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 21px;
  // cursor: pointer;
  .text{
    position: relative;
    z-index: 10;
    color: white;
    user-select: none;
  }
  &::after, &::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px; top: 0px;
    
    transition: transform 0.2s ease-in;
  }
  &::after{
    transform: rotate(45deg) translate(-5px, 5px);
    border: 3px solid #003c70;
  }
  &::before{
    transform: rotate(45deg) translate(5px, -5px);
    background-color: #44a5ff;
  }

  &:hover{
    &::after{
      transform: rotate(45deg);
    }
    &::before{
      transform: rotate(45deg);
    }
  }
}
.sectionThemedDot{
  width:  20px;
  height: 20px;
  background-color: #44a5ff;
  margin: 20px auto 0 auto;
  border-radius: 50%;
  &:nth-child(2){
    margin-top: 45px;
  }
  &:nth-child(3){
    transform: scale(0.8);
  }
  &:nth-child(4){
    transform: scale(0.6);
  }
  &:nth-child(5){
    transform: scale(0.4);
  }
}
.sectionTitle{
  margin: 25px 35px 25px 25px;
  width: 100px;
}
.sectionItems{
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: calc(100% - 140px);
}



// Template

// .sectionThemedDot{
//   background-color: #06ad38;
// }
// .hoverableLogo{
//   &::before{
//     background-color: #06ad38;
//   }
//   &::after{
//     border-color: #174704;
//   }
// }
// .fadeInButton{
//   &::before{
//     background-color: #06ad38;
//   }
//   &::after{
//     border-color: #174704;
//   }
// }