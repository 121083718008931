.mainTiles{
  width: 50%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .tilesContainer{
    margin: 0 auto 0 auto;

    display: grid;
    grid-template-columns: repeat(4, 100px);
    grid-template-rows: repeat(3, 100px);
  }
  a{
    text-decoration: none;
  }

  .tile{
    position: relative;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    color: #333;
    cursor: pointer;
    margin: 5px;
    user-select: none;
    transition: 0.1s ease-in color;
    .text{
      position: relative; 
      z-index: 10;
    }
    &::after{
      content: "";
      transform: rotate(45deg);
      position: absolute;
      left: 0px; top: 0px;
      width: 100%;
      height: 100%;
      border: 3px solid black;
      background-color: #ffffff4d;

      transition: 0.2s ease-in background-color,
                  0.2s ease-in border-color;
    }
  }
  .myWorkTile{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    margin: 5px;
    width: 190px;
    height: 190px;
    font-size: 35px;
    &:hover{
      color: white;
      &::after{
        // background-color: #44a5ff;
        border-color: #003c70;
        background-color: #44a5ff;
      }
    }
  }
  .blogTile{
    // transform: translate(-80px, -90px);
    transform: translate(-111px, -20px);
    &:hover{
      color: white;
      &::after{
        border-color: #702900;
        background-color: #ffbe44;

        border-color: #27032c;
        background-color: #b716bd;

      }
    }
  }
  .facebookTile{
    // transform: translate(-110px, -20px);
    transform: translate(120px, -10px);
    &:hover{
      color: white;
      &::after{
        border-color: #00213d;
        background-color: #3b5998;
        // background-color: #ffbc41;
      }
    }
  }
  .contactTileLink{
    transform: translate(10px, -30px);
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
  }
  .contactTile{
    margin: 5px;
    width: 190px;
    height: 190px;
    font-size: 35px;
    &:hover{
      color: white;
      &::after{
        border-color: #174704;
        background-color: #06ad38;
      }
    }
  }
}
