.myName{
  min-height: 100vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .nameContainer{
    font-size: 90px;
    // text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
    color: black;
    background-color: #fff;
    font-weight: bold;
    width: 75%;
    padding: 20px;
    padding-left: 50px;
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
    // text-align: center;
  
    // -webkit-text-fill-color: black;
    // -webkit-text-stroke-width: 2px;
    // -webkit-text-stroke-color: white;
    .name{
      line-height: 1.1em;
    }
    .subName{
      // text-align: left;
      color: #888;
      padding-left: 3px;
      font-size: 25px;
    }
  }
}